:root{
	--brand-color: #089544;
  }
  .sp-brand{
	color: #089544 !important;
  }
  .sp-bg-brand{
	background-color: #089544 !important;
}
.text-brand{
	color: var(--brand-color);
}

.bg-slate-100 {
    --tw-bg-opacity: 1;
    background-color: rgb(241 245 249 / var(--tw-bg-opacity)) /* #f1f5f9 */;
}

.bg-slate-300 {
    --tw-bg-opacity: 1;
    background-color: rgb(203 213 225 / var(--tw-bg-opacity)) /* #cbd5e1 */;
}

.w-half{
	width: 50%;
}
.w-40{
	width: 40%;
}

.w-45{
	width: 45%;
}
.py-8{
	padding-top: 2rem;
    padding-bottom: 2rem;
}
.pb-8{
	padding-bottom: 2rem;
}

.min-h-60 {
    min-height: 15rem ;
}

.aspect-square{
	aspect-ratio: 1/1;
}

.rounded{
	border-radius: 0.5rem;
}
.rounded-xl{
	border-radius: 1rem;
}

@media (min-width: 768px) {
    .md\:grid-cols-4 {
        grid-template-columns: repeat(4, minmax(0, 1fr));
    }
}
/* Element based Styling */

/* Find Your State Section */
.find-your-state{
    background: url('../images/state/state_banner.jpeg');
    background-size: cover;
    background-position-x: center;
    background-position-y: 30%;
}

.custom-input-icon{
	top: 23px;
	left : -45%;
}


/* Mortgage Calculator */
input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type="range"] {
    -webkit-appearance: none;  
}

input[type="range"]::-webkit-slider-runnable-track {
    
    height: 1rem;
    cursor: pointer;
    background: #f8fafc;
    border-radius: 0.5rem;
    border: 1px solid #7cabe9;
}

input[type="range"]::-webkit-slider-thumb {
    -webkit-appearance: none;  
/*     height: 1rem; */
    width: 1rem;
/*     border-radius: 50% 50% 0 0;   */     
    background: transparent;       
    cursor: pointer;
    margin-top: 6px;          
    border-bottom: 1rem solid #00ac56;
	border-top: 0 solid transparent;
	border-left: 0.5rem solid transparent;
	border-right: 0.5rem solid transparent;  
}

#taxTable {
	border-collapse: collapse;

	table, th, td{
		/* border : 2px solid rgb(255, 0, 0); */
	}
	th, td{
		text-align: center;
	}

}


/* Buyer Page */

.sp-container{
  width : 90%;
  margin:  0 auto;;
}
.top-filter .selectric {
    display: flex;
    height: 50px;
    width: 100%;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
    border-radius: 8px;
    border-width: 1px;
    border-color: rgb(11 44 61 / var(--tw-border-opacity));
    --tw-border-opacity: 0.6;
    background-color: transparent;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 40px;
    padding-right: 20px;
    font-weight: 300;
    line-height: 1.75;
  }
  
  .top-filter .selectric::-moz-placeholder {
    --tw-text-opacity: 1;
    color: rgb(73 73 73 / var(--tw-text-opacity));
    opacity: 1;
  }
  
  .top-filter .selectric:-ms-input-placeholder {
    --tw-text-opacity: 1;
    color: rgb(73 73 73 / var(--tw-text-opacity));
    opacity: 1;
  }
  
  .top-filter .selectric::placeholder {
    --tw-text-opacity: 1;
    color: rgb(73 73 73 / var(--tw-text-opacity));
    opacity: 1;
  }
  
  .top-filter .selectric:focus {
    border-color: rgb(179 147 89 / var(--tw-border-opacity));
    --tw-border-opacity: 0.6;
    outline: 2px solid transparent;
    outline-offset: 2px;
    --tw-drop-shadow: drop-shadow(0px 6px 15px rgba(0, 0, 0, 0.1));
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast)
      var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate)
      var(--tw-sepia) var(--tw-drop-shadow);
  }
  .top-filter .selectric .label {
    display: block;
  }
  .top-filter .selectric .label::after {
    right: 0px;
  }
  
  .advanced-searrch-hidden {
    max-height: 0;
    overflow-y: hidden;
    transition: max-height 0.2s ease-out;
}